/**
 * Swagger Invoices
 * Invoices api
 *
 * OpenAPI spec version: 1.0.0
 * Contact: apiteam@swagger.io
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Client } from '../model/client';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ClientService {

    protected basePath = 'https://factuu.com/api/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Create client
     * 
     * @param body Created client object
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createClient(body: Client, observe?: 'body', reportProgress?: boolean): Observable<Client>;
    public createClient(body: Client, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Client>>;
    public createClient(body: Client, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Client>>;
    public createClient(body: Client, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createClient.');
        }

        let headers = this.defaultHeaders;

        // authentication (Api-Key) required
        if (this.configuration.apiKeys["Api-Key"]) {
            headers = headers.set('Api-Key', this.configuration.apiKeys["Api-Key"]);
        }

        // authentication (Token) required
        if (this.configuration.apiKeys["Token"]) {
            headers = headers.set('Token', this.configuration.apiKeys["Token"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Client>(`${this.basePath}/client`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes a client
     * 
     * @param clientId Client id to delete
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteClient(clientId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteClient(clientId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteClient(clientId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteClient(clientId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (clientId === null || clientId === undefined) {
            throw new Error('Required parameter clientId was null or undefined when calling deleteClient.');
        }

        let headers = this.defaultHeaders;

        // authentication (Api-Key) required
        if (this.configuration.apiKeys["Api-Key"]) {
            headers = headers.set('Api-Key', this.configuration.apiKeys["Api-Key"]);
        }

        // authentication (Token) required
        if (this.configuration.apiKeys["Token"]) {
            headers = headers.set('Token', this.configuration.apiKeys["Token"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/client/${encodeURIComponent(String(clientId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find client by ID
     * Returns a single client
     * @param clientId ID of clieent to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getClientById(clientId: number, observe?: 'body', reportProgress?: boolean): Observable<Client>;
    public getClientById(clientId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Client>>;
    public getClientById(clientId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Client>>;
    public getClientById(clientId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (clientId === null || clientId === undefined) {
            throw new Error('Required parameter clientId was null or undefined when calling getClientById.');
        }

        let headers = this.defaultHeaders;

        // authentication (Api-Key) required
        if (this.configuration.apiKeys["Api-Key"]) {
            headers = headers.set('Api-Key', this.configuration.apiKeys["Api-Key"]);
        }

        // authentication (Token) required
        if (this.configuration.apiKeys["Token"]) {
            headers = headers.set('Token', this.configuration.apiKeys["Token"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Client>(`${this.basePath}/client/${encodeURIComponent(String(clientId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all clients
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getClients(observe?: 'body', reportProgress?: boolean): Observable<Array<Client>>;
    public getClients(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Client>>>;
    public getClients(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Client>>>;
    public getClients(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Api-Key) required
        if (this.configuration.apiKeys["Api-Key"]) {
            headers = headers.set('Api-Key', this.configuration.apiKeys["Api-Key"]);
        }

        // authentication (Token) required
        if (this.configuration.apiKeys["Token"]) {
            headers = headers.set('Token', this.configuration.apiKeys["Token"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Client>>(`${this.basePath}/client`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update an existing client
     * 
     * @param body Client object that needs to be added to the store
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateClient(body: Client, observe?: 'body', reportProgress?: boolean): Observable<Client>;
    public updateClient(body: Client, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Client>>;
    public updateClient(body: Client, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Client>>;
    public updateClient(body: Client, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateClient.');
        }

        let headers = this.defaultHeaders;

        // authentication (Api-Key) required
        if (this.configuration.apiKeys["Api-Key"]) {
            headers = headers.set('Api-Key', this.configuration.apiKeys["Api-Key"]);
        }

        // authentication (Token) required
        if (this.configuration.apiKeys["Token"]) {
            headers = headers.set('Token', this.configuration.apiKeys["Token"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Client>(`${this.basePath}/client`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
