export * from './client.service';
import { ClientService } from './client.service';
export * from './company.service';
import { CompanyService } from './company.service';
export * from './invoice.service';
import { InvoiceService } from './invoice.service';
export * from './prefix.service';
import { PrefixService } from './prefix.service';
export * from './user.service';
import { UserService } from './user.service';
export const APIS = [ClientService, CompanyService, InvoiceService, PrefixService, UserService];
