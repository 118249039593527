import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { Invoice } from './api';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  public static DEBUG = false;

  constructor(
    private storage: Storage,
    private router: Router,
    private toast: ToastController
  ) { }

  renewToken() {
    this.storage.remove("token").then(() => {
      this.router.navigateByUrl('/login');
    });
  }

  async showMsg(msg: string) {
    const t = await this.toast.create({
       message: msg,
       duration: 3000
    });
    t.present();
  }

  log(err: any) {
    if (UtilService.DEBUG) console.log(err);
  }

  handleError(err: any, msg: string = null) {
    this.log(err);
    if (err.status == 401) this.renewToken();
    else this.showMsg(msg != null ? msg : "Error cargando datos");
  }

  public static formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    var mm = ''; var dd = '';
    if (month < 10) mm = "0" + month;
    else mm = '' + month;
    const day = date.getDate();
    if (day < 10) dd = "0" + day;
    else dd = '' + day;
    return year + "-" + mm + "-" + dd;
  }

  public static itemsTotalNoVat(invoice: Invoice): number {
    let total = 0;
    if (invoice.items)
      for (let i = 0; i < invoice.items.length; i++) {
        const inv = invoice.items[i];
        total += (inv.price * inv.quantity);
      }
    return total;
  }

  public static calculateSubtotal(invoice: Invoice): number {
    let total = this.itemsTotalNoVat(invoice);
    return total;
  }

  public static calculateVat(invoice: Invoice): number {
    let total = 0;
    if (invoice.items)
      for (let i = 0; i < invoice.items.length; i++) {
        const inv = invoice.items[i];
        total += (inv.price * (inv.vat / 100.0) * inv.quantity);
      }
    return total;
  }

  public static calculateIrpf(invoice: Invoice): number {
    let subtotal = this.calculateSubtotal(invoice);
    return (subtotal * (invoice.irpf / 100.0));
  }

  public static calculateTotal(invoice: Invoice): number {
    let subtotal = this.calculateSubtotal(invoice);
    let vat = this.calculateVat(invoice);
    let irpf = this.calculateIrpf(invoice);
    return subtotal + vat - irpf;
  }

  public static getClientAddress(invoice: Invoice) {
    let clientAddress = '';
    if (invoice != undefined) {
      clientAddress += invoice.client_name + "\n";
      if (invoice.client_address1 != undefined && invoice.client_address1 != '') {
        clientAddress += invoice.client_address1 + "\n";
      }
      if (invoice.client_address2 != undefined && invoice.client_address2 != '') {
        clientAddress += invoice.client_address2 + "\n";
      }
      if (invoice.client_address3 != undefined && invoice.client_address3 != '') {
        clientAddress += invoice.client_address3 + "\n";
      }
      if (invoice.client_nif != undefined && invoice.client_nif != '') {
        clientAddress += "NIF: " + invoice.client_nif + "\n";
      }
      clientAddress = clientAddress.substr(0, clientAddress.length - 1);
    }
    return clientAddress;
  }

  public static getIssuerAddress(invoice: Invoice) {
    let issuerAddress = '';
    issuerAddress += invoice.issuer_name + "\n";
    if (invoice.issuer_address1 != '') {
      issuerAddress += invoice.issuer_address1 + "\n";
    }
    if (invoice.issuer_address2 != '') {
      issuerAddress += invoice.issuer_address2 + "\n";
    }
    if (invoice.issuer_address3 != '') {
      issuerAddress += invoice.issuer_address3 + "\n";
    }
    if (invoice.issuer_nif != '') {
      issuerAddress += "NIF: " + invoice.issuer_nif + "\n";
    }
    if (invoice.issuer_ic_nif != '') {
      issuerAddress += "NIF intracomunitario: " + invoice.issuer_ic_nif + "\n";
    }
    if (invoice.phone != '') {
      issuerAddress += "Tel: " + invoice.phone + "\n";
    }
    if (invoice.email != '') {
      issuerAddress += "Email: " + invoice.email + "\n";
    }
    issuerAddress = issuerAddress.substr(0, issuerAddress.length - 1);
    return issuerAddress;
  }

  public static convertBase64ToBlob(b64Data, contentType): Blob {
    contentType = contentType || '';
    const sliceSize = 512;
    b64Data = b64Data.replace(/^[^,]+,/, '');
    b64Data = b64Data.replace(/\s/g, '');
    const byteCharacters = window.atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
         const slice = byteCharacters.slice(offset, offset + sliceSize);
         const byteNumbers = new Array(slice.length);
         for (let i = 0; i < slice.length; i++) {
             byteNumbers[i] = slice.charCodeAt(i);
         }
         const byteArray = new Uint8Array(byteNumbers);
         byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, {type: contentType});
  }
}
