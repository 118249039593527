/**
 * Swagger Invoices
 * Invoices api
 *
 * OpenAPI spec version: 1.0.0
 * Contact: apiteam@swagger.io
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Item } from './item';


export interface Invoice { 
    id?: number;
    prefix?: string;
    number?: number;
    invoice_date?: string;
    issuer_name?: string;
    issuer_address1?: string;
    issuer_address2?: string;
    issuer_address3?: string;
    issuer_nif?: string;
    issuer_ic_nif?: string;
    client_name?: string;
    client_address1?: string;
    client_address2?: string;
    client_address3?: string;
    client_nif?: string;
    phone?: string;
    email?: string;
    payment?: string;
    observations?: string;
    irpf?: number;
    /**
     * Invoice Status
     */
    status?: Invoice.StatusEnum;
    items?: Array<Item>;
    company_id?: number;
}
export namespace Invoice {
    export type StatusEnum = 'pending' | 'paid';
    export const StatusEnum = {
        Pending: 'pending' as StatusEnum,
        Paid: 'paid' as StatusEnum
    };
}
