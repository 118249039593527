/**
 * Swagger Invoices
 * Invoices api
 *
 * OpenAPI spec version: 1.0.0
 * Contact: apiteam@swagger.io
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Invoice } from '../model/invoice';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class InvoiceService {

    protected basePath = 'https://factuu.com/api/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Add a new invoice to the store
     * 
     * @param body Invoice object that needs to be added to the store
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addInvoice(body: Invoice, observe?: 'body', reportProgress?: boolean): Observable<Invoice>;
    public addInvoice(body: Invoice, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Invoice>>;
    public addInvoice(body: Invoice, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Invoice>>;
    public addInvoice(body: Invoice, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addInvoice.');
        }

        let headers = this.defaultHeaders;

        // authentication (Api-Key) required
        if (this.configuration.apiKeys["Api-Key"]) {
            headers = headers.set('Api-Key', this.configuration.apiKeys["Api-Key"]);
        }

        // authentication (Token) required
        if (this.configuration.apiKeys["Token"]) {
            headers = headers.set('Token', this.configuration.apiKeys["Token"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Invoice>(`${this.basePath}/invoice`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes a invoice
     * 
     * @param invoiceId Invoice id to delete
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteInvoice(invoiceId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteInvoice(invoiceId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteInvoice(invoiceId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteInvoice(invoiceId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (invoiceId === null || invoiceId === undefined) {
            throw new Error('Required parameter invoiceId was null or undefined when calling deleteInvoice.');
        }

        let headers = this.defaultHeaders;

        // authentication (Api-Key) required
        if (this.configuration.apiKeys["Api-Key"]) {
            headers = headers.set('Api-Key', this.configuration.apiKeys["Api-Key"]);
        }

        // authentication (Token) required
        if (this.configuration.apiKeys["Token"]) {
            headers = headers.set('Token', this.configuration.apiKeys["Token"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/invoice/${encodeURIComponent(String(invoiceId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Finds invoice by year
     * 
     * @param startDate Start date filter
     * @param endDate End date filter, default now
     * @param type If 1 then proforma; if not then normal invoice
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findInvoiceByDate(startDate: string, endDate?: string, type?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Invoice>>;
    public findInvoiceByDate(startDate: string, endDate?: string, type?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Invoice>>>;
    public findInvoiceByDate(startDate: string, endDate?: string, type?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Invoice>>>;
    public findInvoiceByDate(startDate: string, endDate?: string, type?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (startDate === null || startDate === undefined) {
            throw new Error('Required parameter startDate was null or undefined when calling findInvoiceByDate.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('startDate', <any>startDate);
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('endDate', <any>endDate);
        }
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('type', <any>type);
        }

        let headers = this.defaultHeaders;

        // authentication (Api-Key) required
        if (this.configuration.apiKeys["Api-Key"]) {
            headers = headers.set('Api-Key', this.configuration.apiKeys["Api-Key"]);
        }

        // authentication (Token) required
        if (this.configuration.apiKeys["Token"]) {
            headers = headers.set('Token', this.configuration.apiKeys["Token"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Invoice>>(`${this.basePath}/invoice/findByDate`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Finds next invoice number by prefix ID
     * 
     * @param prefix Prefix ID to search
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findInvoiceNextNumber(prefix: string, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public findInvoiceNextNumber(prefix: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public findInvoiceNextNumber(prefix: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public findInvoiceNextNumber(prefix: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (prefix === null || prefix === undefined) {
            throw new Error('Required parameter prefix was null or undefined when calling findInvoiceNextNumber.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (prefix !== undefined && prefix !== null) {
            queryParameters = queryParameters.set('prefix', <any>prefix);
        }

        let headers = this.defaultHeaders;

        // authentication (Api-Key) required
        if (this.configuration.apiKeys["Api-Key"]) {
            headers = headers.set('Api-Key', this.configuration.apiKeys["Api-Key"]);
        }

        // authentication (Token) required
        if (this.configuration.apiKeys["Token"]) {
            headers = headers.set('Token', this.configuration.apiKeys["Token"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<number>(`${this.basePath}/invoice/findNextNumber`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find invoice by ID
     * Returns a single invoice
     * @param invoiceId ID of invoice to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInvoiceById(invoiceId: number, observe?: 'body', reportProgress?: boolean): Observable<Invoice>;
    public getInvoiceById(invoiceId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Invoice>>;
    public getInvoiceById(invoiceId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Invoice>>;
    public getInvoiceById(invoiceId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (invoiceId === null || invoiceId === undefined) {
            throw new Error('Required parameter invoiceId was null or undefined when calling getInvoiceById.');
        }

        let headers = this.defaultHeaders;

        // authentication (Api-Key) required
        if (this.configuration.apiKeys["Api-Key"]) {
            headers = headers.set('Api-Key', this.configuration.apiKeys["Api-Key"]);
        }

        // authentication (Token) required
        if (this.configuration.apiKeys["Token"]) {
            headers = headers.set('Token', this.configuration.apiKeys["Token"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Invoice>(`${this.basePath}/invoice/${encodeURIComponent(String(invoiceId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update an existing invoice
     * 
     * @param body Invoice object that needs to be added to the store
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateInvoice(body: Invoice, observe?: 'body', reportProgress?: boolean): Observable<Invoice>;
    public updateInvoice(body: Invoice, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Invoice>>;
    public updateInvoice(body: Invoice, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Invoice>>;
    public updateInvoice(body: Invoice, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateInvoice.');
        }

        let headers = this.defaultHeaders;

        // authentication (Api-Key) required
        if (this.configuration.apiKeys["Api-Key"]) {
            headers = headers.set('Api-Key', this.configuration.apiKeys["Api-Key"]);
        }

        // authentication (Token) required
        if (this.configuration.apiKeys["Token"]) {
            headers = headers.set('Token', this.configuration.apiKeys["Token"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Invoice>(`${this.basePath}/invoice`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
