/**
 * Swagger Invoices
 * Invoices api
 *
 * OpenAPI spec version: 1.0.0
 * Contact: apiteam@swagger.io
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Login } from '../model/login';
import { LoginResponse } from '../model/loginResponse';
import { ResetPassword } from '../model/resetPassword';
import { Signup } from '../model/signup';
import { User } from '../model/user';
import { UserChangeData } from '../model/userChangeData';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class UserService {

    protected basePath = 'https://factuu.com/api/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    /**
     * Create user and company
     * 
     * @param body Created user and company object
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createUserAndCompany(body: Signup, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public createUserAndCompany(body: Signup, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public createUserAndCompany(body: Signup, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public createUserAndCompany(body: Signup, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createUserAndCompany.');
        }

        let headers = this.defaultHeaders;

        // authentication (Api-Key) required
        if (this.configuration.apiKeys["Api-Key"]) {
            headers = headers.set('Api-Key', this.configuration.apiKeys["Api-Key"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/signup`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get current logged in user data
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserData(observe?: 'body', reportProgress?: boolean): Observable<User>;
    public getUserData(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public getUserData(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public getUserData(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Api-Key) required
        if (this.configuration.apiKeys["Api-Key"]) {
            headers = headers.set('Api-Key', this.configuration.apiKeys["Api-Key"]);
        }

        // authentication (Token) required
        if (this.configuration.apiKeys["Token"]) {
            headers = headers.set('Token', this.configuration.apiKeys["Token"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<User>(`${this.basePath}/user`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Logs user into the system
     * 
     * @param login User email or username and password
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loginUser(login: Login, observe?: 'body', reportProgress?: boolean): Observable<LoginResponse>;
    public loginUser(login: Login, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LoginResponse>>;
    public loginUser(login: Login, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LoginResponse>>;
    public loginUser(login: Login, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (login === null || login === undefined) {
            throw new Error('Required parameter login was null or undefined when calling loginUser.');
        }

        let headers = this.defaultHeaders;

        // authentication (Api-Key) required
        if (this.configuration.apiKeys["Api-Key"]) {
            headers = headers.set('Api-Key', this.configuration.apiKeys["Api-Key"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<LoginResponse>(`${this.basePath}/user/login`,
            login,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Logs out current logged in user session
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public logoutUser(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public logoutUser(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public logoutUser(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public logoutUser(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Api-Key) required
        if (this.configuration.apiKeys["Api-Key"]) {
            headers = headers.set('Api-Key', this.configuration.apiKeys["Api-Key"]);
        }

        // authentication (Token) required
        if (this.configuration.apiKeys["Token"]) {
            headers = headers.set('Token', this.configuration.apiKeys["Token"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.basePath}/user/logout`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Reset password with the email
     * 
     * @param body Email of the user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resetPassword(body: ResetPassword, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public resetPassword(body: ResetPassword, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public resetPassword(body: ResetPassword, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public resetPassword(body: ResetPassword, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling resetPassword.');
        }

        let headers = this.defaultHeaders;

        // authentication (Api-Key) required
        if (this.configuration.apiKeys["Api-Key"]) {
            headers = headers.set('Api-Key', this.configuration.apiKeys["Api-Key"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/user/resetPassword`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update current user email
     * 
     * @param body Old and new user email for this user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateUserEmail(body: UserChangeData, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateUserEmail(body: UserChangeData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateUserEmail(body: UserChangeData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateUserEmail(body: UserChangeData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateUserEmail.');
        }

        let headers = this.defaultHeaders;

        // authentication (Api-Key) required
        if (this.configuration.apiKeys["Api-Key"]) {
            headers = headers.set('Api-Key', this.configuration.apiKeys["Api-Key"]);
        }

        // authentication (Token) required
        if (this.configuration.apiKeys["Token"]) {
            headers = headers.set('Token', this.configuration.apiKeys["Token"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/user/email`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update current user password
     * 
     * @param body Old and new user password for this user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateUserPassword(body: UserChangeData, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateUserPassword(body: UserChangeData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateUserPassword(body: UserChangeData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateUserPassword(body: UserChangeData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateUserPassword.');
        }

        let headers = this.defaultHeaders;

        // authentication (Api-Key) required
        if (this.configuration.apiKeys["Api-Key"]) {
            headers = headers.set('Api-Key', this.configuration.apiKeys["Api-Key"]);
        }

        // authentication (Token) required
        if (this.configuration.apiKeys["Token"]) {
            headers = headers.set('Token', this.configuration.apiKeys["Token"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/user/password`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
