/**
 * Swagger Invoices
 * Invoices api
 *
 * OpenAPI spec version: 1.0.0
 * Contact: apiteam@swagger.io
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Prefix } from '../model/prefix';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PrefixService {

    protected basePath = 'https://factuu.com/api/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Create prefix
     * This can only be done by the logged in user.
     * @param body Created prefix object
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createPrefix(body: Prefix, observe?: 'body', reportProgress?: boolean): Observable<Prefix>;
    public createPrefix(body: Prefix, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Prefix>>;
    public createPrefix(body: Prefix, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Prefix>>;
    public createPrefix(body: Prefix, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createPrefix.');
        }

        let headers = this.defaultHeaders;

        // authentication (Api-Key) required
        if (this.configuration.apiKeys["Api-Key"]) {
            headers = headers.set('Api-Key', this.configuration.apiKeys["Api-Key"]);
        }

        // authentication (Token) required
        if (this.configuration.apiKeys["Token"]) {
            headers = headers.set('Token', this.configuration.apiKeys["Token"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Prefix>(`${this.basePath}/prefix`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes a prefix
     * 
     * @param prefixId Prefix id to delete
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deletePrefix(prefixId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deletePrefix(prefixId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deletePrefix(prefixId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deletePrefix(prefixId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (prefixId === null || prefixId === undefined) {
            throw new Error('Required parameter prefixId was null or undefined when calling deletePrefix.');
        }

        let headers = this.defaultHeaders;

        // authentication (Api-Key) required
        if (this.configuration.apiKeys["Api-Key"]) {
            headers = headers.set('Api-Key', this.configuration.apiKeys["Api-Key"]);
        }

        // authentication (Token) required
        if (this.configuration.apiKeys["Token"]) {
            headers = headers.set('Token', this.configuration.apiKeys["Token"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/prefix/${encodeURIComponent(String(prefixId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find prefix by ID
     * Returns a single prefix
     * @param prefixId ID of prefix to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPrefixById(prefixId: number, observe?: 'body', reportProgress?: boolean): Observable<Prefix>;
    public getPrefixById(prefixId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Prefix>>;
    public getPrefixById(prefixId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Prefix>>;
    public getPrefixById(prefixId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (prefixId === null || prefixId === undefined) {
            throw new Error('Required parameter prefixId was null or undefined when calling getPrefixById.');
        }

        let headers = this.defaultHeaders;

        // authentication (Api-Key) required
        if (this.configuration.apiKeys["Api-Key"]) {
            headers = headers.set('Api-Key', this.configuration.apiKeys["Api-Key"]);
        }

        // authentication (Token) required
        if (this.configuration.apiKeys["Token"]) {
            headers = headers.set('Token', this.configuration.apiKeys["Token"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Prefix>(`${this.basePath}/prefix/${encodeURIComponent(String(prefixId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all prefixes
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPrefixes(observe?: 'body', reportProgress?: boolean): Observable<Array<Prefix>>;
    public getPrefixes(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Prefix>>>;
    public getPrefixes(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Prefix>>>;
    public getPrefixes(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Api-Key) required
        if (this.configuration.apiKeys["Api-Key"]) {
            headers = headers.set('Api-Key', this.configuration.apiKeys["Api-Key"]);
        }

        // authentication (Token) required
        if (this.configuration.apiKeys["Token"]) {
            headers = headers.set('Token', this.configuration.apiKeys["Token"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Prefix>>(`${this.basePath}/prefix`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update an existing prefix
     * 
     * @param body Prefix object that needs to be added to the store
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePrefix(body: Prefix, observe?: 'body', reportProgress?: boolean): Observable<Prefix>;
    public updatePrefix(body: Prefix, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Prefix>>;
    public updatePrefix(body: Prefix, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Prefix>>;
    public updatePrefix(body: Prefix, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updatePrefix.');
        }

        let headers = this.defaultHeaders;

        // authentication (Api-Key) required
        if (this.configuration.apiKeys["Api-Key"]) {
            headers = headers.set('Api-Key', this.configuration.apiKeys["Api-Key"]);
        }

        // authentication (Token) required
        if (this.configuration.apiKeys["Token"]) {
            headers = headers.set('Token', this.configuration.apiKeys["Token"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Prefix>(`${this.basePath}/prefix`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
