import { Component, OnInit, OnDestroy } from '@angular/core';

import { Platform, ToastController, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Storage } from '@ionic/storage';
import { InAppPurchase2, IAPProduct } from '@ionic-native/in-app-purchase-2/ngx';
import { UtilService } from './util.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public appPages = [
    {
      title: 'Facturas',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'Proformas',
      url: '/proforma',
      icon: 'copy'
    },
    {
      title: 'Clientes',
      url: '/clients',
      icon: 'contacts'
    },
    {
      title: 'Empresa',
      url: '/settings',
      icon: 'briefcase'
    },
    {
      title: 'Perfil',
      url: '/profile',
      icon: 'person'
    },
    {
      title: 'Cerrar sesión',
      url: '/signout',
      icon: 'power'
    }
  ];

  public appPagesNotLoggedIn = [
    {
      title: 'Entra',
      url: '/login',
      icon: 'log-in'
    },
    {
      title: 'Regístrate',
      url: '/signup',
      icon: 'person-add'
    }
  ];

  static loggedIn: boolean = false;

  static state: any = {
    error: '',
    status: -1,
    product1: null,
    product2: null
  };

  orderMonthly = () => this.store.order(AppComponent.state.product1);
  orderYearly = () => this.store.order(AppComponent.state.product2);
  manageSubscriptions = () => this.store.manageSubscriptions();

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storage: Storage,
    private store: InAppPurchase2,
    private utils: UtilService,
    private router: Router,
    private navigate: NavController
  ) {
    this.initializeApp();
  }

  ngOnInit() {
    document.addEventListener('iapp.monthly', this.orderMonthly);
    document.addEventListener('iapp.yearly', this.orderYearly);
    document.addEventListener('iapp.manage', this.manageSubscriptions);
  }

  ngOnDestroy() {
    document.removeEventListener('iapp.monthly', this.orderMonthly);
    document.removeEventListener('iapp.yearly', this.orderYearly);
    document.removeEventListener('iapp.manage', this.manageSubscriptions);
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.storage.get("token").then((data: string) => {
        if (data != null) {
          AppComponent.loggedIn = true;
        }
      });

      if (this.platform.is('cordova')) {
        if (this.platform.is('android')) {
          this.statusBar.backgroundColorByHexString("#2e86c1");
        } else {
          this.statusBar.styleDefault();
        }
        this.splashScreen.hide();

        this.initPurchases();
      }

      this.initBackEvent();
    });
  }

  initPurchases() {
    this.store.validator = "https://validator.fovea.cc/v1/validate?appName=com.factuu&apiKey=6432189a-ce4b-45b5-8095-96e35841c543";
    this.store.register([{
      id: "renovacion_mensual",
      type: this.store.PAID_SUBSCRIPTION,
    }, {
      id: "renovacion_anual",
      type: this.store.PAID_SUBSCRIPTION,
    }]);
    this.store.when("subscription")
      .approved((p: IAPProduct) => {
        this.utils.log('approved subscription');
        this.utils.log(p);
        p.verify();
        //document.dispatchEvent(new Event('iapp.verify'));
      })
      .verified((p: IAPProduct) => {
        this.utils.log('verified subscription');
        this.utils.log(p);
        p.finish();
        //document.dispatchEvent(new Event('iapp.finish'));
      });

    this.store.error((error: any) => {
      AppComponent.setState({ error: `ERROR ${error.code}: ${error.message}` });
      this.utils.showMsg(`ERROR ${error.code}: ${error.message}`);
    });
  
    // Called when any subscription product is updated
    this.store.when('subscription').updated((p) => {
      this.utils.log('updated subscription');
      this.utils.log(p);

      const product1: any = this.store.get('renovacion_mensual') || {};
      const product2: any = this.store.get('renovacion_anual') || {};

      let status = 0; //'Por favor, elija un plan';
      if (product2.owned)
        status = 2; //'Subscribed yearly';
      else if (product1.owned)
        status = 1; //'Subscribed monthly';
      else if (product1.state === 'approved' || product2.state === 'approved')
        status = 3; // 'Processing...';

      AppComponent.setState({ product1: product1, product2: product2, status: status });
      document.dispatchEvent(new Event('iapp.update'));
    });

    this.store.refresh();
  }

  initBackEvent() {
    this.platform.backButton.subscribe(() => {
      if (this.router.url == '/login' || this.router.url == '/home') {
        navigator['app'].exitApp();
      } else {
        this.navigate.back();
      }
    });
  }

  static setState(attr: any) {
    Object.assign(AppComponent.state, attr);
  }

  static getState() {
    return AppComponent.state;
  }

  get staticLoggedIn() {
    return AppComponent.loggedIn;
  }
}
